.number_addcard .ant-input-number {
  width: 100%;
}

.card-select .ant-form-item-label {
  margin-right: 1.8rem;
}

.shipping {
  min-width: 100px;
}
.vendor {
  min-width: 120px;
}
.payment {
  align-items: center;
}

.orderTable {
  min-width: 90px;
}

.orderTable_link {
  min-width: 80px;
}
#form_in_modal_profile_img
  > span
  > div
  > div.ant-upload-list-picture-card-container
  > div
  > span
  > a {
  display: none !important;
}

/* custom scroll css start / */
/* / width / */
::-webkit-scrollbar {
  width: 5px;
}

/* / Track / */
::-webkit-scrollbar-track {
  background: #f8f9fc;
  border-radius: 10px;
}

/* / Handle / */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

/* / Handle on hover */
*/ ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.googleMap {
  display: flex;
  flex-direction: column;
}

.googleMapForm {
  box-sizing: border-box;
  margin: 0px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0px;
  padding: 8.5px 11px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  line-height: 1.5;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(230, 235, 241);
  border-radius: 0.625rem;
  transition: all 0.3s ease 0s;
}
.googleMapFormLabel {
  color: rgb(26, 51, 83);
  font-weight: 500;
  padding: 0px 0px 8px;
}

.react-tel-input .country-list {
  width: 230px !important;
}

/* .form-control {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8.5px 11px;
  color: #455560;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e6ebf1;
  border-radius: 0.625rem;
  transition: all 0.3s;
} */
.react-tel-input {
  border: none !important;
  box-sizing: border-box;
  transition: all 0.3s;
}

.react-tel-input:hover .phoneInput,
.react-tel-input:hover .flag-dropdown {
  border: 1px solid rgb(233, 74, 34) !important;
  transition: all 0.1s;
}
.react-tel-input:hover .flag-dropdown{
  border-right: solid 1px #cacaca !important;
}
.react-tel-input:focus .phoneInput,
.react-tel-input:focus .flag-dropdown {
  border: 1px solid rgb(233, 74, 34) !important;
  transition: all 0.1s;
}
.react-tel-input:focus .flag-dropdown{
  border-right: solid 1px #cacaca !important;
}
.form-control:hover,
.googleMapForm:hover {
  border-color: rgb(233, 74, 34) !important;
  border-right-width: 1px !important;
}
.form-control:focus,
.googleMapForm:focus {
  border-color: rgb(233, 74, 34) !important;
  outline: 0px;
  box-shadow: rgb(62 121 247 / 20%) 0px 0px 0px 2px;
  border-right-width: 1px !important;
}

.special-label {
  display: none;
}
